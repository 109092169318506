const config = {
  ENVIRONMENT_NAME: 'prod',
  forwoodId: {
    URL: 'https://id.vale.forwoodsafety.com',
    APP_CLIENT_ID: '1nt409p0sko8r7d2brqmoav04n',
    USER_TOKEN_URL: 'https://9ktnk0nfyd.execute-api.us-east-2.amazonaws.com/prod'
  },
  apiGateway: {
    REGION: 'us-east-2',
    URL: 'https://xoj1dd5gze.execute-api.us-east-2.amazonaws.com/prod'
  },
  reactApp: {
    VERSION: 'teams-container-1.9.1',
    HOSTNAME: 'https://teams.vale.forwoodsafety.com',
    TEAMS_MICROFRONTEND_URL: 'https://teams-service.vale.forwoodsafety.com',
    TARGETS_MICROFRONTEND_URL: 'https://targets.vale.forwoodsafety.com',
    TEAMS_ACCESS_ROLE: 'TeamsAccess',
    TEAM_TARGETS_ACCESS_ROLE: 'TeamTargetsAccess',
  },
  configurationService: {
    URL: 'https://config.vale.forwoodsafety.com',
    WEBSOCKET: 'wss://ytsci10m9h.execute-api.us-east-2.amazonaws.com/prod'
  },
  get forwoodIdAuthUrl() {
    return `${this.forwoodId.URL}/authorize?response_type=token&scope=openid&client_id=${this.forwoodId.APP_CLIENT_ID}&redirect_uri=${this.reactApp.HOSTNAME}&state=`;
  }
};

export default config;
